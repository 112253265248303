*{
    font-family: 'Playfair Display', serif;
}
.CTA{
    width:50vw;
    margin:auto;
    position: absolute;
    color:white;
    font-size: 7vw;
    font-family: 'Playfair Display', serif;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* if a/r is less than 1:1 change font size to 10 vw  */

.pdesc{
    line-height: 3rem;
}