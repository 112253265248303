.page {
    background-color: white;
    justify-content: center;
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;
    box-sizing: border-box;
    padding-top: 10vh;
}

.paragraph {
    margin-top: 15vh;
}

.box {
    border: rgba(88, 88, 88, 0.315);
    background-color: rgba(230, 230, 250, 0);
    border-radius: 1rem;
    border-style: solid;
    border-width: .2px;
    text-align: left;
    margin-bottom: 10vh;
    margin: 2.5% 2.5% 2.5% 2.5%;
    line-height: 1.5rem;
    display: flex;
    width: inherit;
}

.header {
    text-align: center;
}

.boxcontent {
    margin: 2% 2% 2% 2%;
    width: inherit;
}

.boxbody {
    display: inline-block;
    flex-wrap: wrap;
    width: 100%;
}

.col {
    display: flex;
    flex-grow: 1;
    margin: 0;
    padding: 0;
}

.row {
    display: flex;
    flex-grow: 1;
    margin: 0;
    padding: 0;
}

.locimage {
    width: 100%;
    margin: 0;
    padding: 0;
}

img {
    margin: 5% 0% 5% 0%;
    width: 100%;
    filter: brightness(.8)
}

/* [name=locations] .box {
    background-color: rgba(133, 133, 133, 0.048);
} */
@media  screen and (max-width: 650px) {
    .tiny {
        display: show!important;
    }
    .large {
        display: none;
    }
    .small {
        display: none;
    }
}

@media screen and (max-width: 1450px) and (min-width: 651px) {
    .large {
        display: none;
    }
    .small {
        display: show;
    }
    .tiny {
        display: none;
    }
}

@media only screen and (min-width: 1451px) {
    .large {
        display: show;
    }
    .small {
        display: none;
    }
    .tiny {
        display: none;
    }
}

iframe{
   width:99vw;
   overflow-y: hidden!important;
}
#schedulepage{
    max-height: 99vh;
    overflow-y: hidden!important;
}

@media only screen and (max-width: 1650px) {
    #schedulepage{
        overflow-y:visible!important;
    }
    iframe{
        overflow-y:hidden!important;
    }
}
.footer {
    background-color: black;
    left:0;
    bottom:0;
    color:white;
    width:100vw;
    box-shadow:-10px;
    -webkit-box-shadow: 0px -2px 4px 1px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px -2px 4px 1px rgba(50, 50, 50, 0.75);
    box-shadow: 0px -2px 4px -1px rgb(0 0 0 / 20%), 0px -4px 5px 0px rgb(0 0 0 / 14%), 0px -1px 10px 0px rgb(0 0 0 / 12%);;
    z-index: 1;
    float:left;
}

.footer .MuiSvgIcon-root{
    margin-left:.5vw;
    float:left;
}
.footersection{
    background-color: white;
    height: fit-content;
    padding-top:5vh;
}
.shamelessplug{
    float: right;
    margin-right:2vw;
}
.shamelessplug .MuiSvgIcon-root{
    float:right;
}
.footer{
    padding-top:1vh;
    padding-bottom:1vh;
}

.social{
    margin-left:1.5vw;
}
@media only screen and (max-width: 850px) {
    .col{
        width:70vw!important;
    }
    .social{
        margin-left:2.5vw;
    }
}

